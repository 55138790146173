// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_footer__s4fH0 {
  background: #424749;
  box-sizing: border-box;
}
.styles_footer__s4fH0 .styles_greyWrapper__nxnNw {
  width: 100%;
  box-sizing: border-box;
  color: white;
}
.styles_footer__s4fH0 .styles_greyWrapper__nxnNw .styles_link__B6wEa {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: color 0.2s ease-in-out;
  cursor: pointer;
  gap: 8px;
}
.styles_footer__s4fH0 .styles_greyWrapper__nxnNw .styles_link__B6wEa .styles_icon__XkiUq {
  transition: fill 0.2s ease-in-out;
  fill: white;
}
.styles_footer__s4fH0 .styles_greyWrapper__nxnNw .styles_link__B6wEa:hover {
  color: #6bb7be;
}
.styles_footer__s4fH0 .styles_greyWrapper__nxnNw .styles_link__B6wEa:hover .styles_icon__XkiUq {
  fill: #6bb7be;
}
.styles_footer__s4fH0 .styles_whiteWrapper__pC86R {
  background: white;
  box-sizing: border-box;
  padding: 10px 0;
  width: 100%;
}
.styles_footer__s4fH0 .styles_whiteWrapper__pC86R .styles_content__SIOOu, .styles_footer__s4fH0 .styles_greyWrapper__nxnNw .styles_content__SIOOu {
  max-width: 1200px;
}

@media screen and (max-width: 1400px) {
  footer .styles_whiteWrapper__pC86R .styles_content__SIOOu, footer .styles_greyWrapper__nxnNw .styles_content__SIOOu {
    max-width: calc(100% - 100px) !important;
  }
}
@media screen and (max-width: 600px) {
  footer .styles_whiteWrapper__pC86R .styles_content__SIOOu, footer .styles_greyWrapper__nxnNw .styles_content__SIOOu {
    max-width: calc(100% - 24px) !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,sBAAA;AACF;AACE;EACE,WAAA;EACA,sBAAA;EACA,YAAA;AACJ;AACI;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,kCAAA;EACA,eAAA;EACA,QAAA;AACN;AACM;EACE,iCAAA;EACA,WAAA;AACR;AAEM;EACE,cAAA;AAAR;AAEQ;EACE,aAAA;AAAV;AAME;EACE,iBAAA;EACA,sBAAA;EACA,eAAA;EACA,WAAA;AAJJ;AAQI;EACE,iBAAA;AANN;;AAWA;EAGM;IACE,wCAAA;EAVN;AACF;AAeA;EAGM;IACE,uCAAA;EAfN;AACF","sourcesContent":[".footer {\r\n  background: #424749;\r\n  box-sizing: border-box;\r\n\r\n  .greyWrapper {\r\n    width: 100%;\r\n    box-sizing: border-box;\r\n    color: white;\r\n\r\n    .link {\r\n      display: flex;\r\n      flex-direction: column;\r\n      align-items: center;\r\n      transition: color 0.2s ease-in-out;\r\n      cursor: pointer;\r\n      gap: 8px;\r\n\r\n      .icon {\r\n        transition: fill 0.2s ease-in-out;\r\n        fill: white;\r\n      }\r\n\r\n      &:hover {\r\n        color: #6bb7be;\r\n\r\n        .icon {\r\n          fill: #6bb7be;\r\n        }\r\n      }\r\n    }\r\n  }\r\n\r\n  .whiteWrapper {\r\n    background: white;\r\n    box-sizing: border-box;\r\n    padding: 10px 0;\r\n    width: 100%;\r\n  }\r\n\r\n  .whiteWrapper, .greyWrapper {\r\n    .content {\r\n      max-width: 1200px;\r\n    }\r\n  }\r\n}\r\n\r\n@media screen and (max-width: 1400px) {\r\n  footer {\r\n    .whiteWrapper, .greyWrapper {\r\n      .content {\r\n        max-width: calc(100% - 100px) !important;\r\n      }\r\n    }\r\n  }\r\n}\r\n\r\n@media screen and (max-width: 600px) {\r\n  footer {\r\n    .whiteWrapper, .greyWrapper {\r\n      .content {\r\n        max-width: calc(100% - 24px) !important;\r\n      }\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `styles_footer__s4fH0`,
	"greyWrapper": `styles_greyWrapper__nxnNw`,
	"link": `styles_link__B6wEa`,
	"icon": `styles_icon__XkiUq`,
	"whiteWrapper": `styles_whiteWrapper__pC86R`,
	"content": `styles_content__SIOOu`
};
export default ___CSS_LOADER_EXPORT___;
