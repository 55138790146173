import { useState } from "react";
import Page from "./Page";
import Footer from "./components/Footer";
import Header from "./components/Header";
import { useTranslation } from "react-i18next";
import "./App.scss";
import "./i18n";

function App() {
  const [show, setShow] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <Header props={{ setShow }} />
      <div>
        <Page props={{ show }} />
      </div>
      <Footer props={{ show }} />
      <a href={`#top`} className={`scrollToTop`}>{t("ontop")}</a>
    </>
  );
}

export default App;
