// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_card__NubT7 {
  padding: 25px;
  background: white;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 10px;
  min-height: 100%;
  width: 360px;
}
.styles_card__NubT7 .styles_title__Y-JDG {
  font-size: 20px;
  font-weight: 800;
  text-transform: uppercase;
  text-align: center;
  margin: 0;
}
.styles_card__NubT7 .styles_price__eYeCW {
  display: inline-flex;
  font-weight: 400;
  font-size: 24px;
}
.styles_card__NubT7 .styles_price__eYeCW .styles_bold__gbW2j {
  font-weight: 800;
}
.styles_card__NubT7 .styles_ul__AVqjJ {
  padding-left: 18px !important;
}

@media screen and (max-width: 1200px) {
  .styles_card__NubT7 {
    width: 290px;
  }
}
@media screen and (max-width: 1000px) {
  .styles_card__NubT7 {
    width: 360px;
  }
}
@media screen and (max-width: 400px) {
  .styles_card__NubT7 {
    width: calc(100% - 20px);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Card/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EAGA,iBAAA;EACA,sBAAA;EACA,gBAAA;EACA,mBAAA;EACA,gBAAA;EACA,YAAA;AADF;AA4BE;EACE,eAAA;EACA,gBAAA;EACA,yBAAA;EACA,kBAAA;EACA,SAAA;AA1BJ;AA6BE;EACE,oBAAA;EACA,gBAAA;EACA,eAAA;AA3BJ;AA6BI;EACE,gBAAA;AA3BN;AA+BE;EACE,6BAAA;AA7BJ;;AAiCA;EACE;IACE,YAAA;EA9BF;AACF;AAiCA;EACE;IACE,YAAA;EA/BF;AACF;AAkCA;EACE;IACE,wBAAA;EAhCF;AACF","sourcesContent":[".card {\r\n  padding: 25px;\r\n  // background: #f9fafa;\r\n  // background: #ececec;\r\n  background: white;\r\n  box-sizing: border-box;\r\n  overflow: hidden;\r\n  border-radius: 10px;\r\n  min-height: 100%;\r\n  width: 360px;\r\n\r\n  // .box {\r\n  //   overflow: hidden;\r\n  //   height: 245px;\r\n\r\n  //   .contentWrapper {\r\n  //     margin-bottom: 0px;\r\n  //     transition: margin-bottom 0.6s ease-in-out;\r\n\r\n  //     .img, .content {\r\n  //       padding: 25px;\r\n  //       width: 100%;\r\n\r\n  //       &.about {\r\n  //         overflow: auto;\r\n  //       }\r\n  //     }\r\n  //   }\r\n\r\n  //   &.show {\r\n  //     .contentWrapper {\r\n  //       margin-bottom: -245px;\r\n  //     }\r\n  //   }\r\n  // }\r\n\r\n  .title {\r\n    font-size: 20px;\r\n    font-weight: 800;\r\n    text-transform: uppercase;\r\n    text-align: center;\r\n    margin: 0;\r\n  }\r\n\r\n  .price {\r\n    display: inline-flex;\r\n    font-weight: 400;\r\n    font-size: 24px;\r\n\r\n    .bold {\r\n      font-weight: 800;\r\n    }\r\n  }\r\n\r\n  .ul {\r\n    padding-left: 18px !important;\r\n  }\r\n}\r\n\r\n@media screen and (max-width: 1200px) {\r\n  .card {\r\n    width: 290px;\r\n  }\r\n}\r\n\r\n@media screen and (max-width: 1000px) {\r\n  .card {\r\n    width: 360px;\r\n  }\r\n}\r\n\r\n@media screen and (max-width: 400px) {\r\n  .card {\r\n    width: calc(100% - 20px);\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `styles_card__NubT7`,
	"title": `styles_title__Y-JDG`,
	"price": `styles_price__eYeCW`,
	"bold": `styles_bold__gbW2j`,
	"ul": `styles_ul__AVqjJ`
};
export default ___CSS_LOADER_EXPORT___;
