// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__ubW-a {
  display: flex;
  width: 100%;
}
.styles_container__ubW-a.styles_column__S6ur2 {
  flex-direction: column;
}
.styles_container__ubW-a.styles_column__S6ur2.styles_left__oMcnN {
  align-items: flex-start;
}
.styles_container__ubW-a.styles_column__S6ur2.styles_center__N4B7l {
  align-items: center;
}
.styles_container__ubW-a.styles_column__S6ur2.styles_right__ITbHZ {
  align-items: flex-end;
}
.styles_container__ubW-a.styles_column__S6ur2.styles_top__f09Ea {
  justify-content: flex-start;
}
.styles_container__ubW-a.styles_column__S6ur2.styles_middle__rk4KY {
  justify-content: center;
}
.styles_container__ubW-a.styles_column__S6ur2.styles_bottom__rsGyr {
  justify-content: flex-end;
}
.styles_container__ubW-a.styles_row__2cVa7 {
  flex-direction: row;
}
.styles_container__ubW-a.styles_row__2cVa7.styles_left__oMcnN {
  justify-content: flex-start;
}
.styles_container__ubW-a.styles_row__2cVa7.styles_center__N4B7l {
  justify-content: center;
}
.styles_container__ubW-a.styles_row__2cVa7.styles_right__ITbHZ {
  justify-content: flex-end;
}
.styles_container__ubW-a.styles_row__2cVa7.styles_top__f09Ea {
  align-items: flex-start;
}
.styles_container__ubW-a.styles_row__2cVa7.styles_middle__rk4KY {
  align-items: center;
}
.styles_container__ubW-a.styles_row__2cVa7.styles_bottom__rsGyr {
  align-items: flex-end;
}
.styles_container__ubW-a.styles_spaceBetween__AuS-X {
  justify-content: space-between !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Container/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,WAAA;AACF;AACE;EACE,sBAAA;AACJ;AACI;EACE,uBAAA;AACN;AAEI;EACE,mBAAA;AAAN;AAGI;EACE,qBAAA;AADN;AAII;EACE,2BAAA;AAFN;AAKI;EACE,uBAAA;AAHN;AAMI;EACE,yBAAA;AAJN;AAQE;EACE,mBAAA;AANJ;AAQI;EACE,2BAAA;AANN;AASI;EACE,uBAAA;AAPN;AAUI;EACE,yBAAA;AARN;AAWI;EACE,uBAAA;AATN;AAYI;EACE,mBAAA;AAVN;AAaI;EACE,qBAAA;AAXN;AAeE;EACE,yCAAA;AAbJ","sourcesContent":[".container {\r\n  display: flex;\r\n  width: 100%;\r\n\r\n  &.column {\r\n    flex-direction: column;\r\n\r\n    &.left {\r\n      align-items: flex-start;\r\n    }\r\n\r\n    &.center {\r\n      align-items: center;\r\n    }\r\n\r\n    &.right {\r\n      align-items: flex-end;\r\n    }\r\n\r\n    &.top {\r\n      justify-content: flex-start;\r\n    }\r\n\r\n    &.middle {\r\n      justify-content: center;\r\n    }\r\n\r\n    &.bottom {\r\n      justify-content: flex-end;\r\n    }\r\n  }\r\n\r\n  &.row {\r\n    flex-direction: row;\r\n\r\n    &.left {\r\n      justify-content: flex-start;\r\n    }\r\n\r\n    &.center {\r\n      justify-content: center;\r\n    }\r\n\r\n    &.right {\r\n      justify-content: flex-end;\r\n    }\r\n\r\n    &.top {\r\n      align-items: flex-start;\r\n    }\r\n\r\n    &.middle {\r\n      align-items: center;\r\n    }\r\n\r\n    &.bottom {\r\n      align-items: flex-end;\r\n    }\r\n  }\r\n\r\n  &.spaceBetween {\r\n    justify-content: space-between !important;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__ubW-a`,
	"column": `styles_column__S6ur2`,
	"left": `styles_left__oMcnN`,
	"center": `styles_center__N4B7l`,
	"right": `styles_right__ITbHZ`,
	"top": `styles_top__f09Ea`,
	"middle": `styles_middle__rk4KY`,
	"bottom": `styles_bottom__rsGyr`,
	"row": `styles_row__2cVa7`,
	"spaceBetween": `styles_spaceBetween__AuS-X`
};
export default ___CSS_LOADER_EXPORT___;
