import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import translationEn from "./locales/en/translation.json";
import translationRu from "./locales/ru/translation.json";
import translationRo from "./locales/ro/translation.json";

const resources = {
  en: {
    translation: translationEn
  },
  ru: {
    translation: translationRu
  },
  ro: {
    translation: translationRo
  }
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: "en",
    fallbackLng: ["en"],
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    resources,
    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ["br"],
      useSuspense: true,
    },
  });

export default i18n;