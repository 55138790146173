import { ReactComponent as FaArrowLeft } from "../../images/arrow-left.svg";
import { HTMLAttributes } from "react";
import styles from "./styles.module.scss";

export type ArrowType = HTMLAttributes<HTMLDivElement> & { currentSlide?: number, slideCount?: number };

const NextArrow = ({ currentSlide, slideCount, onClick, className, ...props }: ArrowType) => {
  return (
    <span
      className={`${styles.arrow} ${styles.prev} ${className ?? ""}`}
      onClick={onClick}
      {...props}
    >
      <FaArrowLeft />
    </span>
  )
};

export default NextArrow;
