import Container, { Direction, HorizontalAlign } from "../Container";
import { ReactComponent as PhoneIcon } from "../../images/phone.svg";
import { ReactComponent as EnvelopeIcon } from "../../images/envelope.svg";
import { ReactComponent as MarkerIcon } from "../../images/marker.svg";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import styles from "./styles.module.scss";

const Footer = ({ props }: any) => {
  const { t } = useTranslation();
  const [width, setWidth] = useState(0);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize)
    };
  }, [setWidth]);

  return (
    <footer className={styles.footer}>
      <Container
        horizontalAlign={HorizontalAlign.Center}
        className={`${styles.greyWrapper} pv-50`}
      >
        <Container
          className={styles.content}
          direction={width > 1000 ? Direction.Row : Direction.Column}
          gap={width > 1000 ? 0 : 30}
          spaceBetween={true}
        >
          <Container>
            <span className={styles.link}>
              <PhoneIcon className={styles.icon} width={24} height={24} />
              <p className={`m-0`}>{t("footer.text1")}</p>
            </span>
          </Container>
          <Container>
            <span className={styles.link}>
              <EnvelopeIcon className={styles.icon} width={24} height={24} />
              <p className={`m-0`}>Email : info@kmm.md</p>
            </span>
          </Container>
          <Container>
            <span className={styles.link}>
              <MarkerIcon className={styles.icon} width={24} height={24} />
              <p className={`m-0`}>Str C. Brancusi 124/1, Chisinau 2060</p>
            </span>
          </Container>
        </Container>
      </Container>
      <Container horizontalAlign={HorizontalAlign.Center} direction={Direction.Row} className={styles.whiteWrapper}>
        <Container className={styles.content}>
          <Container horizontalAlign={HorizontalAlign.Left}>
            <p className={`m-0`}>{t("footer.text2")}</p>
          </Container>
          <Container horizontalAlign={HorizontalAlign.Right}>
            {/* <p className={`m-0`}>Developed by KM</p> */}
          </Container>
        </Container>
      </Container>
    </footer>
  )
}

export default Footer;
