import Container, { Direction, HorizontalAlign, VerticalAlign } from "../Container";
import { useEffect, useState, useRef } from "react";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import Hamburger from "hamburger-react";
import logo from "../../images/logo.png";
import arrow from "../../images/arrow.png";

const Header = ({ props }: any) => {
  const dropdownVariation = ["en", "ru", "ro"];
  const [width, setWidth] = useState(0);
  const [show, setShow] = useState(false);
  const { t, i18n } = useTranslation();
  const [dropdown, setDropdown] = useState(false);
  const [dropdownValue, setDropdownValue] = useState(dropdownVariation[0]);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const sections = [
    {
      text: t("section1"),
      id: "#project"
    },
    {
      text: t("section2"),
      id: "#gate"
    },
    {
      text: t("section3"),
      id: "#cases"
    },
    {
      text: t("section4"),
      id: "#about"
    },
  ];

  const changeLanguageHandler = (lang: string) => {
    i18n.changeLanguage(lang);
  };

  function openDropdown() {
    if (!dropdown) {
      setDropdown(true);
      if (dropdownRef.current) {
        dropdownRef.current.style.display = "block";
        setTimeout(() => dropdownRef.current!.classList.add(styles.active), 100);
      }
    } else {
      setDropdown(false);
      if (dropdownRef.current) {
        dropdownRef.current.classList.remove(styles.active);
        setTimeout(() => dropdownRef.current!.style.display = "none", 300);
      }
    }
  };

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setWidth]);

  useEffect(() => {
    setTimeout(() => {
      if (dropdownRef.current) {
        dropdownRef.current.style.display = "none";
      }
    }, 300);
  }, []);

  useEffect(() => {
    setDropdownValue(dropdownValue);
  }, [setDropdownValue]);

  useEffect(() => {
    if (width > 1200) {
      props.setShow(false);
      setShow(false);
    };
  }, [width]);

  return (
    <main className={show ? styles.spaceToggle : ""}>
      <header className={styles.header}>
        {width > 1200 ? (
          <Container className={styles.content} direction={Direction.Row}>
            <Container horizontalAlign={HorizontalAlign.Left}>
              <img src={logo} className={styles.logo} alt="logo" />
            </Container>
            <Container
              direction={Direction.Row}
              horizontalAlign={HorizontalAlign.Left}
              verticalAlign={VerticalAlign.Middle}
              gap={20}
            >
              {sections.map((section, id) => (
                <a href={section.id} className={styles.text} key={id}>
                  {section.text}
                </a>
              ))}
            </Container>
            <Container direction={Direction.Row} horizontalAlign={HorizontalAlign.Right} gap={4}>
              <span
                className={`${styles.lang}`}
                onClick={openDropdown}
              >
                <img src={arrow} width={16} height={16} alt="arrow" />
                <p className={`m-0`}>{dropdownValue}</p>
              </span>
            </Container>
            <div ref={dropdownRef} className={styles.select} onClick={openDropdown}>
              {dropdownVariation.map((lang, id) => {
                if (lang !== dropdownValue) {
                  return (
                    <div
                      key={id}
                      className={styles.option}
                      onClick={() => {
                        setDropdownValue(lang);
                        changeLanguageHandler(lang);
                      }}
                    >
                      {lang}
                    </div>
                  )
                }
              })}
            </div>
          </Container>
        ) : (
          <Container className={styles.content} direction={Direction.Row}>
            <Container horizontalAlign={HorizontalAlign.Left} verticalAlign={VerticalAlign.Middle}>
              <div
                onClick={() => {
                  setShow(!show);
                  props.setShow(!show);
                }}
                className={styles.burgerWr}
              >
                <Hamburger size={28} duration={0.5} toggled={show} />
              </div>
            </Container>
            <Container>
              <img src={logo} className={styles.logo} width={150} height={80} alt="" />
            </Container>
            <Container>
            </Container>
          </Container>
        )}
        {
          (dropdown && width >= 1200) ? (
            <div className={`${styles.field}`} onMouseMove={() => openDropdown()}></div>
          ) : <></>
        }
      </header>
      {
        width < 1200 ? (
          <aside className={`${styles.sidebar} ${show ? styles.show : ""}`}>
            <Container direction={Direction.Column} gap={20}>
              <Container gap={20}>
                {sections.map((section, id) => (
                  <a href={section.id} className={styles.text} key={id}>
                    {section.text}
                  </a>
                ))}
              </Container>
              <Container>
                <span
                  className={`${styles.lang}`}
                  onClick={openDropdown}
                >
                  <img src={arrow} width={16} height={16} alt="arrow" />
                  <p className={`m-0`}>{dropdownValue}</p>
                </span>
                <div ref={dropdownRef} className={styles.select} onClick={openDropdown}>
                  {dropdownVariation.map((lang, id) => {
                    if (lang !== dropdownValue) {
                      return (
                        <div
                          key={id}
                          className={styles.option}
                          onClick={() => {
                            setDropdownValue(lang);
                            changeLanguageHandler(lang);
                          }}
                        >
                          {lang}
                        </div>
                      )
                    }
                  })}
                </div>
              </Container>
            </Container>
            {dropdown ? (
              <div className={`${styles.field}`} onMouseMove={() => openDropdown()}></div>
            ) : <></>}
          </aside>
        ) : <></>
      }
    </main>
  )
}

export default Header;
