import Container, { HorizontalAlign } from "../Container";
import standart from "../../images/standart.png";
import advanced from "../../images/advanced.jpg";
import styles from "./styles.module.scss";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const Table = () => {
  const [width, setWidth] = useState<number>(0);
  const { t } = useTranslation();
  const data = [{
    cell1: "",
    cell2: t("table.tr1.td2"),
    cell3: t("table.tr1.td3"),
  }, {
    cell1: t("table.tr2.td1"),
    cell2: "",
    cell3: "",
  }, {
    cell1: t("table.tr3.td1"),
    cell2: t("table.tr3.td2"),
    cell3: t("table.tr3.td3"),
  }, {
    cell1: t("table.tr4.td1"),
    cell2: t("table.tr4.td2"),
    cell3: t("table.tr4.td3"),
  }, {
    cell1: t("table.tr5.td1"),
    cell2: t("table.tr5.td2"),
    cell3: t("table.tr5.td3"),
  }, {
    cell1: t("table.tr6.td1"),
    cell2: t("table.tr6.td2"),
    cell3: t("table.tr6.td3"),
  }, {
    cell1: t("table.tr7.td1"),
    cell2: t("table.tr7.td2"),
    cell3: t("table.tr7.td3"),
  }, {
    cell1: t("table.tr8.td1"),
    cell2: t("table.tr8.td2"),
    cell3: t("table.tr8.td3"),
  }, , {
    cell1: t("table.tr9.td1"),
    cell2: "",
    cell3: "",
  }, , {
    cell1: t("table.tr10.td1"),
    cell2: t("table.tr10.td2"),
    cell3: t("table.tr10.td3"),
  }, , {
    cell1: t("table.tr11.td1"),
    cell2: t("table.tr11.td2"),
    cell3: t("table.tr11.td3"),
  }, , {
    cell1: t("table.tr12.td1"),
    cell2: t("table.tr12.td2"),
    cell3: t("table.tr12.td3"),
  }, , {
    cell1: t("table.tr13.td1"),
    cell2: t("table.tr13.td2"),
    cell3: t("table.tr13.td3"),
  }, , {
    cell1: t("table.tr14.td1"),
    cell2: "",
    cell3: "",
  }, , {
    cell1: t("table.tr15.td1"),
    cell2: t("table.tr15.td2"),
    cell3: t("table.tr15.td3"),
  }, , {
    cell1: t("table.tr16.td1"),
    cell2: t("table.tr16.td2"),
    cell3: t("table.tr16.td3"),
  }, , {
    cell1: t("table.tr17.td1"),
    cell2: t("table.tr17.td2"),
    cell3: t("table.tr17.td3"),
  }, , {
    cell1: t("table.tr18.td1"),
    cell2: t("table.tr18.td2"),
    cell3: t("table.tr18.td3"),
  }];

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize)
    };
  }, [setWidth]);

  return (
    <table className={styles.table}>
      <tbody>
        {width > 800 ? (
          <tr>
            <td className={styles.cell}>
              <p className={`${styles.title}`}>{t("title4")}</p>
            </td>
            <td className={styles.cell}>
              <Container width={170} height={170} horizontalAlign={HorizontalAlign.Center}>
                <Container width={130}>
                  <img src={standart} className={styles.img} alt="img" />
                </Container>
              </Container>
            </td>
            <td className={styles.cell}>
              <Container width={170} height={170} horizontalAlign={HorizontalAlign.Center}>
                <Container width={130}>
                  <img src={advanced} className={styles.img} alt="img" />
                </Container>
              </Container>
            </td>
          </tr>
        ) : ""}
        <tr className={styles.row}>
          <td className={`${styles.cell} ${styles.bold}`}></td>
          <td className={`${styles.cell} ${styles.bold}`}>{data[0]?.cell2}</td>
          <td className={`${styles.cell} ${styles.bold}`}>{data[0]?.cell3}</td>
        </tr>
        {data.map((d, id) => {
          if (id > 0) {
            return (
              <tr className={styles.row} key={id}>
                <td className={styles.cell}>{d?.cell1}</td>
                <td className={styles.cell}>{d?.cell2}</td>
                <td className={styles.cell}>{d?.cell3}</td>
              </tr>
            )
          }
        })}
      </tbody>
    </table>
  )
};

export default Table;
