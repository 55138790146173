import styles from "./styles.module.scss";
import { ButtonHTMLAttributes, FunctionComponent, MouseEvent } from "react";

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  text?: string | undefined;
  link?: string | undefined;
  onClick?: () => void;
};

const Button: FunctionComponent<ButtonProps> = ({ text, link, className, onClick, ...props }: ButtonProps) => {
  const handleClick = () => {
    if (link) {
      window.location.href = link;
    }

    if (onClick) {
      onClick();
    }
  };

  return (
    <button
      className={`${styles.button} ${className ?? ""}`}
      onClick={handleClick}
      {...props}
    >
      {link ? (
        <a href={link} className={styles.link}>
          {text}
        </a>
      ) : (
        text
      )}
    </button>
  );
};

export default Button;
