import { DetailedHTMLProps, FunctionComponent, HTMLAttributes } from "react";
import styles from "./styles.module.scss";

export type ContainerProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  direction?: Direction,
  verticalAlign?: VerticalAlign,
  horizontalAlign?: HorizontalAlign,
  gap?: number | string,
  width?: number | string,
  height?: number | string,
  spaceBetween?: boolean
};

export enum Direction {
  Column = "column",
  Row = "row",
}

export enum VerticalAlign {
  Top = "top",
  Middle = "middle",
  Bottom = "bottom",
}

export enum HorizontalAlign {
  Left = "left",
  Center = "center",
  Right = "right",
}

const Container: FunctionComponent<ContainerProps> = ({ className, children, direction, gap, height, horizontalAlign, spaceBetween, style, verticalAlign, width, ...props }: ContainerProps) => {
  if (direction === undefined) {
    direction = Direction.Column;
  }

  if (process.env.NODE_ENV === "development" && spaceBetween) {
    if (direction === Direction.Column && verticalAlign !== undefined) {
      console.warn("The \"spaceBetween\" property is set, so the \"verticalAlign\" one is ignored.");
    }
    else if (direction === Direction.Row && horizontalAlign !== undefined) {
      console.warn("The \"spaceBetween\" property is set, so the \"horizontalAlign\" one is ignored.");
    }
  }

  return (
    <div
      className={`${styles.container} ${styles[direction]} ${styles[horizontalAlign ?? HorizontalAlign.Center]} ${styles[verticalAlign ?? VerticalAlign.Middle]} ${spaceBetween ? styles.spaceBetween : ""} ${className ?? ""}`}
      style={(gap || width || height) ?
        Object.assign({
          gap: typeof gap === "number" ? `${gap}px` : gap,
          width: typeof width === "number" ? `${width}px` : width,
          height: typeof height === "number" ? `${height}px` : height,
        }, style) :
        style
      }
      {...props}
    >
      {children}
    </div>
  );
};

export default Container;