// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_arrow__DJz04 {
  cursor: pointer;
  position: absolute;
  z-index: 1;
}

.styles_arrow__DJz04 svg path {
  transition: fill 0.3s ease-in-out;
}

.styles_arrow__DJz04 svg:hover path {
  fill: #6bb7be;
}

.styles_prev__rF\\+no {
  display: block;
  left: 0%;
  top: calc(50% - 14px) !important;
  transform: translateY(-50%);
}`, "",{"version":3,"sources":["webpack://./src/components/PrevArrow/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,kBAAA;EACA,UAAA;AACF;;AAGE;EACE,iCAAA;AAAJ;;AAKE;EACE,aAAA;AAFJ;;AAMA;EACE,cAAA;EACA,QAAA;EACA,gCAAA;EACA,2BAAA;AAHF","sourcesContent":[".arrow {\r\n  cursor: pointer;\r\n  position: absolute;\r\n  z-index: 1;\r\n}\r\n\r\n.arrow svg {\r\n  path {\r\n    transition: fill 0.3s ease-in-out;\r\n  }\r\n}\r\n\r\n.arrow svg:hover {\r\n  path {\r\n    fill: #6bb7be;\r\n  }\r\n}\r\n\r\n.prev {\r\n  display: block;\r\n  left: 0%;\r\n  top: calc(50% - 14px) !important;\r\n  transform: translateY(-50%);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"arrow": `styles_arrow__DJz04`,
	"prev": `styles_prev__rF+no`
};
export default ___CSS_LOADER_EXPORT___;
