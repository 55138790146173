// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_button__cLVM3 {
  padding: 10px 45px;
  background: #007bff;
  border: 1px solid #007bff;
  transition: all 0.2s ease-in-out;
  text-transform: uppercase;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.2;
  text-align: center;
  letter-spacing: 0;
  width: 100%;
  color: white;
  cursor: pointer;
}
.styles_button__cLVM3 .styles_link__8nMvS {
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  color: white;
}
.styles_button__cLVM3:hover {
  background: white;
  color: #007bff;
}
.styles_button__cLVM3:hover .styles_link__8nMvS {
  color: #007bff;
}`, "",{"version":3,"sources":["webpack://./src/components/Button/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,mBAAA;EACA,yBAAA;EACA,gCAAA;EACA,yBAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,kBAAA;EACA,iBAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;AACF;AACE;EACE,qBAAA;EACA,gCAAA;EACA,YAAA;AACJ;AAEE;EACE,iBAAA;EACA,cAAA;AAAJ;AAEI;EACE,cAAA;AAAN","sourcesContent":[".button {\r\n  padding: 10px 45px;\r\n  background: #007bff;\r\n  border: 1px solid #007bff;\r\n  transition: all 0.2s ease-in-out;\r\n  text-transform: uppercase;\r\n  border-radius: 5px;\r\n  font-size: 18px;\r\n  font-weight: 400;\r\n  line-height: 1.2;\r\n  text-align: center;\r\n  letter-spacing: 0;\r\n  width: 100%;\r\n  color: white;\r\n  cursor: pointer;\r\n\r\n  .link {\r\n    text-decoration: none;\r\n    transition: all 0.2s ease-in-out;\r\n    color: white;\r\n  }\r\n  \r\n  &:hover {\r\n    background: white;\r\n    color: #007bff;\r\n\r\n    .link {\r\n      color: #007bff;\r\n    }\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `styles_button__cLVM3`,
	"link": `styles_link__8nMvS`
};
export default ___CSS_LOADER_EXPORT___;
