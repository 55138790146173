import Button from "../Button";
import Container, { HorizontalAlign, VerticalAlign } from "../Container";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import { ButtonProps } from "../Button";
// import { useState } from "react";

interface CardProps {
  list1: string[];
  list2?: string[];
  text?: string;
  title?: string;
  price?: string;
  buttonProps?: ButtonProps;
};

const Card: React.FC<CardProps> = ({ title, price, list1, list2, text, buttonProps }: CardProps) => {
  const { t } = useTranslation();
  // const [show, setShow] = useState<boolean>(false);
  // function showContent() {
  //   setShow(!show)
  // };

  return (
    <Container
      verticalAlign={VerticalAlign.Top}
      className={styles.card}
    >
      <Container height={60}>
        <p className={styles.title}>{title}</p>
      </Container>
      <Container
        horizontalAlign={HorizontalAlign.Left}
        verticalAlign={VerticalAlign.Top}
        height={260}
      >
        <ul className={`${styles.ul} m-0`}>
          {list1.map((li1, id) => (
            <li className={id == 0 ? "mt-3" : "mt-2"} key={id}>
              <p className={`m-0`}>{li1}</p>
              {list2 && list2.map((li2, idx) => (
                id == 0 && <p className={`ml-1 mt-1 mb-0`} key={idx}>{li2}</p>
              ))}
            </li>
          ))}
        </ul>
      </Container>
      <Container>
        <Container>
          <div className={`${styles.price} mt-2`}>
            <p className={styles.bold}>${price}</p>
            <p>/{t("cards.card1.text3")}</p>
          </div>
        </Container>
      </Container>
      <Button {...buttonProps} />
      <hr />
      <p className={`mt-4`}>{text}</p>
      {/* <Container verticalAlign={VerticalAlign.Bottom} className={`${styles.box} ${show ? styles.show : ""}`}>
        <div className={styles.contentWrapper}>
          <Container height={260}>
            <Container className={`${styles.content} ${styles.about}`}>
              <ol></ol>
            </Container>
          </Container>
          <Container height={260}>
          </Container>
        </div>
      </Container> */}
    </Container>
  );
};

export default Card;