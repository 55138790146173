import Container, { Direction, HorizontalAlign, VerticalAlign } from "../components/Container";
import styles from "./styles.module.scss";
import Card from "../components/Card";
import engine from "../images/engine.png";
// import Button from "../components/Button";
// import chiller from "../images/chiller.png";
// import climat from "../images/climat.png";
// import dashboard from "../images/dashboard.png";
// import kotelnye from "../images/kotelnye.png";
// import meters from "../images/meters.png";
// import serverRoom from "../images/server_room.jpg";
// import pt from "../images/pt.png";
// import Input from "../components/Input";
import sensors1 from "../../src/images/sensors1.png";
import sensors2 from "../../src/images/sensors2.png";
import sensors3 from "../../src/images/sensors3.png";
import sensors4 from "../../src/images/sensors4.png";
import sensors5 from "../../src/images/sensors5.png";
import sensors6 from "../../src/images/sensors6.png";
import banner1 from "../images/banner1.png";
import banner2 from "../images/banner2.png";
import banner3 from "../images/banner3.png";
import banner4 from "../images/banner4.png";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import NextArrow from "../components/NextArrow";
import PrevArrow from "../components/PrevArrow";
import Carousel from "react-bootstrap/Carousel";
import CardCarousel from "../components/Carousel";
import quote from "../images/quote.svg";
import avatar1 from "../images/avatar1.png";
import avatar2 from "../images/avatar2.png";
import avatar3 from "../images/avatar3.png";
import avatar4 from "../images/avatar4.png";
import Table from "../components/Table";
import "bootstrap/dist/css/bootstrap.min.css";

// type DataType = {
//   input1: string;
//   input2: string;
//   input3: string;
//   input4: string;
// };

// type InputsFilledType = {
//   [key: string]: boolean | undefined;
// };

const Page = ({ props }: any) => {
  const { t } = useTranslation();
  const [width, setWidth] = useState<number>(0);
  const [_, setIndex] = useState<number>(0);
  const [visible, setVisible] = useState<boolean>(false);
  // const [checked, setChecked] = useState<boolean>(false);
  // const [isFilled, setIsFilled] = useState<InputsFilledType>(
  //   {
  //     input1Filled: false,
  //     input2Filled: false,
  //     input3Filled: false,
  //     input4Filled: false,
  //   });
  // let [data, setData] = useState<DataType>({
  //   input1: "",
  //   input2: "",
  //   input3: "",
  //   input4: "",
  // });
  const inputs = [
    {
      placeholder: t("placeholders.placeholder1"),
      height: 50
    },
    {
      placeholder: t("placeholders.placeholder2"),
      height: 50
    },
    {
      placeholder: t("placeholders.placeholder3"),
      height: 50
    },
    {
      placeholder: t("placeholders.placeholder4"),
      height: 100
    }
  ];
  const handleSelect = (selectedIndex: any) => {
    setIndex(selectedIndex);
  };
  const testimonials = [
    {
      id: 0,
      photo: avatar1,
      name: t("testimonials.testimonial1.name"),
      company: "CDG SRL",
      link: "https://cdg.md/",
      text: t("testimonials.testimonial1.text"),
    },
    {
      id: 1,
      photo: avatar2,
      name: t("testimonials.testimonial2.name"),
      company: "«UNISIM-SOFT» SRL",
      link: "https://una.md/",
      text: t("testimonials.testimonial2.text"),
    },
    {
      id: 2,
      photo: avatar3,
      name: t("testimonials.testimonial3.name"),
      company: "«Vamcomplex» SRL",
      link: "https://www.data2b.md/ro/companies/1003600079589/societatea-comerciala-vamcomplex-srl",
      text: t("testimonials.testimonial3.text"),
    },
    {
      id: 3,
      photo: avatar4,
      name: t("testimonials.testimonial4.name"),
      company: "DEEPLACE SRL",
      link: "https://deeplace.md/",
      text: t("testimonials.testimonial4.text"),
    }
  ];
  const banners = [banner1, banner2, banner3, banner4];
  const liTitles = [
    t("titles.title1"),
    t("titles.title2"),
    t("titles.title3"),
  ];
  const liArray = [
    t("list1.text1"),
    t("list1.text2"),
    t("list1.text3"),
    t("list1.text4"),
    t("list1.text5"),
    t("list1.text6"),
    t("list1.text7"),
    t("list1.text8"),
    t("list1.text9")
  ];
  const liArray2 = [
    t("list2.text1"),
    t("list2.text2"),
    t("list2.text3"),
    t("list2.text4"),
    t("list2.text5"),
    t("list2.text6"),
  ];
  const liArray3 = [
    {
      title: t("block1.list.li1.title"),
      text: t("block1.list.li1.text")
    }, {
      title: t("block1.list.li2.title"),
      text: t("block1.list.li2.text")
    }, {
      title: t("block1.list.li3.title"),
      text: t("block1.list.li3.text")
    }, {
      title: t("block1.list.li4.title"),
      text: t("block1.list.li4.text")
    }, {
      title: t("block1.list.li5.title"),
      text: ""
    }
  ];
  const liArray4 = [
    t("list3.text1"),
    t("list3.text2"),
    t("list3.text3"),
    t("list3.text4"),
  ];
  const liArray5 = [
    t("list4.text1"),
    t("list4.text2"),
    t("list4.text3"),
    t("list4.text4"),
  ];
  const cardData = [
    {
      title: t("cards.card1.title"),
      list1: [
        t("cards.card1.list1.li1"),
        t("cards.card1.list1.li2"),
        t("cards.card1.list1.li3"),
        t("cards.card1.list1.li4"),
      ],
      text: t("cards.card1.text1"),
      price: t("cards.card1.text2"),
      buttonText: t("button1"),
      link: ""
    },
    {
      title: t("cards.card2.title"),
      list1: [
        t("cards.card2.list1.li1"),
        t("cards.card2.list1.li2"),
        t("cards.card2.list1.li3"),
        t("cards.card2.list1.li4"),
        t("cards.card2.list1.li5"),
      ],
      text: t("cards.card2.text1"),
      price: t("cards.card2.text2"),
      buttonText: t("button1"),
      link: ""
    },
    {
      title: t("cards.card3.title"),
      list1: [
        t("cards.card3.list1.li1"),
        t("cards.card3.list1.li2"),
        t("cards.card3.list1.li3"),
        t("cards.card3.list1.li4"),
      ],
      list2: [
        t("cards.card3.list2.li1"),
        t("cards.card3.list2.li2"),
      ],
      text: t("cards.card3.text1"),
      price: t("cards.card3.text2"),
      buttonText: t("button1"),
      link: ""
    },
    {
      title: t("cards.card4.title"),
      list1: [
        t("cards.card4.list1.li1"),
        t("cards.card4.list1.li2"),
        t("cards.card4.list1.li3"),
        t("cards.card4.list1.li4"),
      ],
      text: t("cards.card4.text1"),
      price: t("cards.card4.text2"),
      buttonText: t("button1"),
      link: ""
    },
    {
      title: t("cards.card5.title"),
      list1: [
        t("cards.card5.list1.li1"),
        t("cards.card5.list1.li2"),
        t("cards.card5.list1.li3"),
        t("cards.card5.list1.li4"),
        t("cards.card5.list1.li5"),
      ],
      list2: [
        t("cards.card5.list2.li1"),
        t("cards.card5.list2.li2"),
        t("cards.card5.list2.li3"),
      ],
      text: t("cards.card5.text1"),
      price: t("cards.card5.text2"),
      buttonText: t("button1"),
      link: ""
    },
    {
      title: t("cards.card6.title"),
      list1: [
        t("cards.card6.list1.li1"),
        t("cards.card6.list1.li2"),
        t("cards.card6.list1.li3"),
        t("cards.card6.list1.li4"),
        t("cards.card6.list1.li5"),
        t("cards.card6.list1.li6"),
      ],
      text: t("cards.card6.text1"),
      price: t("cards.card6.text2"),
      buttonText: t("button1"),
      link: ""
    },
  ];
  const images = [sensors1, sensors2, sensors3, sensors4, sensors5, sensors6];

  // const handleInputChange = (inputName: keyof DataType, value: string) => {
  //   setData((prevData) => ({
  //     ...prevData,
  //     [inputName]: value,
  //   }));

  //   let inputValueLength = value.length;
  //   let isInputFilled = inputValueLength == 0;

  //   setIsFilled((prevIsFilled) => ({
  //     ...prevIsFilled,
  //     [`${inputName}Filled`]: !isInputFilled,
  //   }));
  // };
  // function sendData() {
  //   setChecked(true);
  // };

  function confirmFunction(e: any) {
    if (!window.confirm(t("confirm"))) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize)
    };
  }, [setWidth]);

  // \u00A0
  return (
    <Container className={styles.page}>
      <Container className={`${styles.greyWrapper} ${styles.textAlignCenter} pv-50`}>
        <Container className={styles.content}>
          <p className={`${styles.title} mb-4`}>{t("title1")}</p>
        </Container>
        {width < 1300 ? (
          <Container className={`${styles.carouselWr} borderBox`}>
            <Carousel
              indicators={false}
              controls={true}
              prevIcon={<PrevArrow className={styles.arrow1} />}
              nextIcon={<NextArrow className={styles.arrow1} />}
              interval={5000}
              onSelect={handleSelect}
            >
              {banners.map((banner, id: number) => {
                return (
                  <Carousel.Item key={id}>
                    <img className={styles.img} src={banner} alt="img" />
                  </Carousel.Item>
                )
              })}
            </Carousel>
          </Container>
        ) : (
          <Container className={`mt-4 mb-4`}>
            <CardCarousel />
          </Container>
        )}
      </Container>
      <Container className={`${styles.content} mb-4 borderBox`}>
        <Container direction={width > 900 ? Direction.Row : Direction.Column} className={`mt-6`} gap={30}>
          <Container style={{ float: "left" }} verticalAlign={VerticalAlign.Middle} width={width < 600 ? 250 : 450}>
            <img src={engine} className={`${styles.img}`} alt="img" />
          </Container>
          <Container>
            <p className={`${styles.title}`} style={{ textAlign: `${width < 900 ? "center" : "left"}` }}>{t("block1.title")}</p>
            <p className={`mt-2`}>{t("block1.subtitle")}</p>
          </Container>
        </Container>
        <p className={`${styles.miniTitle} mt-5`}>{t("block1.title2")}</p>
        <ol>
          {liArray3.map((data, id) => (
            <li className={`${styles.marker} ${id != 0 ? "mt-2" : "mt-4"}`} key={id}>
              <p style={{ fontWeight: "600", margin: "0" }}>{data.title}</p>
              <p className={``}>{data.text}</p>
            </li>
          ))}
        </ol>
      </Container>
      <Container className={`${styles.greyWrapper} pv-50 mt-6`} id={`project`}>
        <Container className={styles.content}>
          <p className={`${styles.title}`}>{t("title5")}</p>
          <Container
            className={`mt-5`}
            direction={width > 600 ? Direction.Row : Direction.Column}
            gap={width > 800 ? 60 : width > 600 ? 20 : 0}
          >
            {images.slice(3).map((image, id) => (
              <Container className={styles.imgWr} key={id}>
                <img src={image} className={styles.img2} alt="img" />
              </Container>
            ))}
          </Container>
          <Container horizontalAlign={HorizontalAlign.Left} className={`borderBox ${width > 600 ? "mt-5" : "mt-3"}`}>
            {liArray2.map((text, id) => (
              <p key={id} className={`mb-2 mt-0`}>{text}</p>
            ))}
          </Container>
          <Container className={`${width > 600 ? "mt-5" : "mt-3"}`} direction={width > 600 ? Direction.Row : Direction.Column} gap={width > 800 ? 60 : width > 600 ? 20 : 0}>
            {images.slice(0, 3).map((image, id) => (
              <Container className={styles.imgWr} key={id}>
                <img src={image} className={styles.img2} alt="img" />
              </Container>
            ))}
          </Container>
        </Container>
      </Container>
      <Container
        className={`pv-50 mt-6`}
        id={`gate`}
      >
        {/* <Container>
          <img className={styles.img} src={gate} alt="img" />
        </Container> */}
        <Container className={`${styles.content}`}>
          <p className={`${styles.title}`}>{t("title3")}</p>
          <p className={`mt-2`}>{t("subtitle2")}</p>
          <Container gap={30} direction={width > 900 ? Direction.Row : Direction.Column} verticalAlign={VerticalAlign.Top}>
            <Container horizontalAlign={HorizontalAlign.Left}>
              <ol>
                <li className={`${styles.miniTitle} mt-4`}>{liTitles[0]}</li>
              </ol>
              <ul className={`mt-2`}>
                {liArray.map((text, id) => {
                  if (id < 4) {
                    return <li key={id}>{text}</li>
                  }
                })}
              </ul>
            </Container>
            <Container horizontalAlign={HorizontalAlign.Left}>
              <ol start={2}>
                <li className={`${styles.miniTitle} mt-4`}>{liTitles[1]}</li>
              </ol>
              <ul className={`mt-2`}>
                {liArray.map((text, id) => {
                  if (id >= 4 && id < 7) {
                    return <li key={id}>{text}</li>
                  }
                })}
              </ul>
            </Container>
            <Container horizontalAlign={HorizontalAlign.Left}>
              <ol start={3}>
                <li className={`${styles.miniTitle} mt-4`}>{liTitles[2]}</li>
              </ol>
              <ul className={`mt-2`}>
                {liArray.map((text, id) => {
                  if (id >= 7 && id < 10) {
                    return <li key={id}>{text}</li>
                  }
                })}
              </ul>
            </Container>
          </Container>
          {/* <Button link={``} text={`${t("button2")}`} className={`mt-5`} /> */}
        </Container>
        <Container className={`${styles.content} mt-4`}>
          <p className={`mt-4`}>{t("subtitle3")}</p>
          <Container className={`${width > 900 ? "" : "mt-5"}`}>
            <Table />
          </Container>
          <Container horizontalAlign={HorizontalAlign.Left}>
            <p className={`mt-5`}>{t("subtitle4")}</p>
          </Container>
          {/* <p className={`${styles.title}`}>{t("title3")}</p>
          <p className={`mt-2`}>{t("subtitle2")}</p> */}
        </Container>
      </Container>
      <Container className={`${styles.greyWrapper} pv-50 mt-5`}>
        <Container className={styles.content}>
          <p className={`${styles.title}`}>{t("title2")}</p>
          <p className={`mt-2`}>{t("subtitle1")}</p>
        </Container>
        <div className={`${styles.cardWrapper} mt-4`} id={`cases`}>
          {cardData.map((data, id) => (
            <Container horizontalAlign={HorizontalAlign.Center} key={id}>
              <Card
                list1={data.list1}
                list2={data.list2}
                title={data.title}
                text={data.text}
                price={data.price}
                buttonProps={{ link: data.link, text: data.buttonText }}
              ></Card>
            </Container>
          ))}
        </div>
      </Container>
      <p className={`${styles.title} mb-1 mt-6`}>{t("title6")}</p>


      <Container className={`${styles.carouselWr} p-30 borderBox mt-4`}>
        <Carousel
          controls={width < 1400 ? false : true}
          indicators={width > 1400 ? false : true}
          prevIcon={<PrevArrow className={styles.arrow2} />}
          nextIcon={<NextArrow className={styles.arrow2} />}
          onSelect={handleSelect}
          interval={5000}
        >
          {testimonials.map((testimonial) => (
            <Carousel.Item key={testimonial.id}>
              <Container
                className={styles.slideWr}
                direction={width > 900 ? Direction.Row : Direction.Column}
                gap={width > 900 ? 0 : 30}
              >
                <Container className={styles.imgBox}>
                  <img src={testimonial.photo} className={styles.avatar} alt="avatar" />
                </Container>
                <Container className={styles.popupWr}>
                  <img src={quote} className={`mb-2`} alt="icon" />
                  {width < 901 ? (
                    <p className={styles.popup} style={{ opacity: `${visible ? "1" : "0"}` }}>{testimonial.name}</p>
                  ) : ""}
                  <div
                    className={`${styles.name} mb-0`}
                    style={{ cursor: "pointer" }}
                    title={testimonial.name}
                    onTouchStart={() => {
                      setVisible(true);
                      setTimeout(() => setVisible(false), 5000);
                    }}
                  >
                    {testimonial.name}
                  </div>
                  <p className={`${styles.name} mb-2`}>
                    <a href={testimonial.link} onClick={confirmFunction}>{testimonial.company}</a>
                  </p>
                  <div className={styles.review}>
                    <p className="m-0">{testimonial.text}</p>
                  </div>
                </Container>
              </Container>
            </Carousel.Item>
          ))}
        </Carousel>
      </Container>
      <Container
        className={`${styles.greyWrapper} pv-50 mt-6`}
        id={`about`}
      >
        <p className={styles.title}>{t("title7")}</p>
        <Container
          className={`${styles.content} mt-5`}
          direction={width > 900 ? Direction.Row : Direction.Column}
          verticalAlign={VerticalAlign.Top}
          gap={30}
        >
          <Container width={width > 900 ? 450 : "100%"}>
            <p className={styles.miniTitle}>{t("subtitle6")}</p>
            <ul className={`mt-4`}>
              {liArray5.map((li, id) => (
                <li key={id}>{li}</li>
              ))}
            </ul>
          </Container>
          <Container width={width > 900 ? 450 : "100%"}>
            <p className={styles.miniTitle}>{t("subtitle5")}</p>
            <ul className={`mt-4`}>
              {liArray4.map((li, id) => (
                <li key={id}>{li}</li>
              ))}
            </ul>
          </Container>
        </Container>
      </Container>
    </Container>
  )
}

export default Page;