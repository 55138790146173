import { useEffect } from "react";
import styles from "./styles.module.scss";
import bn11 from "../../images/carousel/slices/bn11.png";
import bn12 from "../../images/carousel/slices/bn12.png";
import bn13 from "../../images/carousel/slices/bn13.png";
import bn14 from "../../images/carousel/slices/bn14.png";
import bn15 from "../../images/carousel/slices/bn15.png";
import bn16 from "../../images/carousel/slices/bn16.png";
import bn17 from "../../images/carousel/slices/bn17.png";
import bn18 from "../../images/carousel/slices/bn18.png";
import bn21 from "../../images/carousel/slices/bn21.png";
import bn22 from "../../images/carousel/slices/bn22.png";
import bn23 from "../../images/carousel/slices/bn23.png";
import bn24 from "../../images/carousel/slices/bn24.png";
import bn25 from "../../images/carousel/slices/bn25.png";
import bn26 from "../../images/carousel/slices/bn26.png";
import bn27 from "../../images/carousel/slices/bn27.png";
import bn28 from "../../images/carousel/slices/bn28.png";
import bn31 from "../../images/carousel/slices/bn31.png";
import bn32 from "../../images/carousel/slices/bn32.png";
import bn33 from "../../images/carousel/slices/bn33.png";
import bn34 from "../../images/carousel/slices/bn34.png";
import bn35 from "../../images/carousel/slices/bn35.png";
import bn36 from "../../images/carousel/slices/bn36.png";
import bn37 from "../../images/carousel/slices/bn37.png";
import bn38 from "../../images/carousel/slices/bn38.png";
import bn41 from "../../images/carousel/slices/bn41.png";
import bn42 from "../../images/carousel/slices/bn42.png";
import bn43 from "../../images/carousel/slices/bn43.png";
import bn44 from "../../images/carousel/slices/bn44.png";
import bn45 from "../../images/carousel/slices/bn45.png";
import bn46 from "../../images/carousel/slices/bn46.png";
import bn47 from "../../images/carousel/slices/bn47.png";
import bn48 from "../../images/carousel/slices/bn48.png";

const CardCarousel = () => {
  const images = [
    bn11,
    bn12,
    bn13,
    bn14,
    bn15,
    bn16,
    bn17,
    bn18,
    "",
    bn21,
    bn22,
    bn23,
    bn24,
    bn25,
    bn26,
    bn27,
    bn28,
    "",
    bn31,
    bn32,
    bn33,
    bn34,
    bn35,
    bn36,
    bn37,
    bn38,
    "",
    bn41,
    bn42,
    bn43,
    bn44,
    bn45,
    bn46,
    bn47,
    bn48,
    "",
  ];

  useEffect(() => {
    let radius = 568;
    let autoRotate = true;
    let rotateSpeed = -60;
    let imgWidth = 100;
    let imgHeight = 400;
    let maxRotationY = 3;

    setTimeout(init, 100);
    const odrag = document.getElementById("dragContainer");
    const ospin = document.getElementById("spinContainer");
    if (!ospin) {
      console.error("Could not find the spin-container element.");
      return;
    };

    const aImg = ospin.getElementsByTagName("img");
    const aVid = ospin.getElementsByTagName("video");

    const aImgArray = Array.from(aImg);
    const aVidArray = Array.from(aVid);

    const aEle = [...aImgArray, ...aVidArray];
    ospin!.style.width = imgWidth + "px";
    ospin!.style.height = imgHeight + "px";

    function init() {
      for (let i = 0; i < aEle.length; i++) {
        aEle[i].style.transform =
          "rotateY(" +
          i * (360 / aEle.length) +
          "deg) translateZ(" +
          radius +
          "px)";
      };
    };

    function applyTranform(obj: any) {
      if (tY > maxRotationY) tY = maxRotationY;
      if (tY < -maxRotationY) tY = -maxRotationY;
      obj.style.transform = "rotateX(" + -tY + "deg) rotateY(" + tX + "deg)";
    };

    function playSpin(yes: any) {
      ospin!.style.animationPlayState = yes ? "running" : "paused";
    };

    let desX = 0,
      desY = 0,
      tX = 0,
      tY = 10;

    if (autoRotate) {
      let animationName = rotateSpeed > 0 ? styles.spin : styles.spinRevert;
      ospin!.style.animation = `${animationName} ${Math.abs(
        rotateSpeed
      )}s infinite linear`;
    };

    odrag!.onpointerdown = function (e) {
      if (!odrag) return;

      clearInterval((odrag as any).timer);
      playSpin(false);

      e = e || window.event;
      let sX = e.clientX,
        sY = e.clientY;

      document.onpointermove = function (e) {
        e = e || window.event;
        let nX = e.clientX,
          nY = e.clientY;
        desX = nX - sX;
        desY = nY - sY;
        tX += desX * 0.1;
        tY += desY * 0.1;
        applyTranform(odrag);
        playSpin(false);
        sX = nX;
        sY = nY;
      };

      document.onpointerup = function () {
        if (!odrag) return;

        (odrag as any).timer = setInterval(function () {
          desX *= 0.95;
          desY *= 0.95;
          tX += desX * 0.1;
          tY += desY * 0.1;
          applyTranform(odrag);
          playSpin(false);
          if (Math.abs(desX) < 0.5 && Math.abs(desY) < 0.5) {
            clearInterval((odrag as any).timer);
            playSpin(true);
          };
        }, 17);
        this.onpointermove = this.onpointerup = null;
      };

      odrag!.onpointerleave = function (event) {
        if (!odrag) return;

        clearInterval((odrag as any).timer);
        playSpin(true);

        this.onpointermove = this.onpointerup = null;
      };

      return false;
    };

    return () => { };
  }, []);

  return (
    <div id="dragContainer" className={styles.dragContainer}>
      <div id="spinContainer" className={styles.spinContainer}>
        {images.map((img, id: number) => {
          return (
            <img
              src={img}
              className={`${styles.img} ${id === 8 || id === 17 || id === 26 || id === 35 ? styles.opacity : ""
                }`}
              key={id}
              alt="img"
            />
          )
        })}
      </div>
    </div>
  );
};

export default CardCarousel;
