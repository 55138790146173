import { ReactComponent as FaArrowRight } from "../../images/arrow-right.svg";
import { ArrowType } from "../PrevArrow";
import styles from "./styles.module.scss";

const NextArrow = ({ currentSlide, slideCount, onClick, className, ...props }: ArrowType) => {
  return (
    <span
      className={`${styles.arrow} ${styles.next} ${className ?? ""}`}
      onClick={onClick}
      {...props}
    >
      <FaArrowRight />
    </span>
  )
};

export default NextArrow;
